const hscodeUtils = {
	async getHscode(ctx) {
		let JSs = [];
		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'mhscodes',
				page: 1,
				rows: null
			},
			(response) => {
				if (response.data && response.data.totalRecords > 0) {
					JSs = response.data.data;
				}
			},
			() => {}
		);
		return JSs;
	}
};

export default hscodeUtils;
