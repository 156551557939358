<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<pui-form-header :showHeader="showParentModelHeader">
				<imofalformsform-header :modelPk="parentModelPk" :parentModelName="parentModelName"></imofalformsform-header>
			</pui-form-header>
			<doc-new-version-modal :modelName="modelName" :pkAttribute="pkAttribute"></doc-new-version-modal>
			<!--
			<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary">
				<v-tab :key="0" :href="'#imofalform2'">{{ $t('imofalform2.maintab') }}</v-tab>
			</v-tabs>
			-->
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" lazy :value="'imofalform2'">
					<v-row class="pui-form-layout">
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<pui-field-set :title="$t('imofalform2.identification')">
								<v-row dense>
									<!-- BLNUMBER -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											:id="`blnumber-imofalform2`"
											v-model="model.blnumber"
											:label="$t('imofalform2.blnumber')"
											:disabled="formDisabled"
											required
											toplabel
											maxlength="70"
										></pui-text-field>
									</v-col>
									<!-- MARKNUMBERS -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											:id="`marknumbers-imofalform2`"
											v-model="model.marknumbers"
											:label="$t('imofalform2.marknumbers')"
											:disabled="formDisabled"
											required
											toplabel
											maxlength="512"
										></pui-text-field>
									</v-col>
								</v-row>
							</pui-field-set>
							<v-row dense>
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<pui-field-set :title="$t('imofalform2.cargodetails')">
										<v-row dense>
											<!-- PACKAGETYPECODE -->
											<v-col class="col-12 col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
												<pui-select
													:id="`packagetypecode-imofalform2`"
													:attach="`packagetypecode-imofalform2`"
													:label="$t('imofalform2.packagetypecode')"
													:disabled="formDisabled"
													required
													toplabel
													clearable
													modelName="mpackagetypes"
													v-model="model"
													reactive
													:itemsToSelect="packagetypecodeItemsToSelect"
													:modelFormMapping="{ packagetypecode: 'packagetypecode' }"
													:itemValue="['packagetypecode']"
													:itemText="(item) => `${item.typedescription}`"
												></pui-select>
											</v-col>
											<!-- NUMPACKAGES -->
											<v-col class="col-12 col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
												<pui-number-field
													:id="`numpackages-imofalform2`"
													v-model="model.numpackages"
													:label="$t('imofalform2.numpackages')"
													:disabled="formDisabled"
													required
													toplabel
												></pui-number-field>
											</v-col>
										</v-row>
										<v-row dense style="margin-top: -15px">
											<!-- HSCODE -->

											<v-col class="col-12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
												<pui-select
													:id="`hscode-imofalform2`"
													:attach="`hscode-imofalform2`"
													:label="$t('imofalform2.hscode')"
													:disabled="formDisabled"
													toplabel
													clearable
													modelName="mhscodes"
													v-model="model"
													reactive
													:itemsToSelect="hscodeItemsToSelect"
													:modelFormMapping="{ hscode: 'hscode' }"
													:itemValue="['hscode']"
													:itemText="(item) => `${item.hscode} - ${item.hscodedescription}`"
													:order="{ hscode: 'asc' }"
												>
												</pui-select>
											</v-col>
											<!-- GOODDESCRIPTION  -->
											<v-col class="col-12 col-xs-12 col-sm-9 col-md-9 col-lg-9 col-xl-9">
												<pui-text-field
													:id="`gooddescription-imofalform2`"
													v-model="model.gooddescription"
													:label="$t('imofalform2.gooddescription')"
													:disabled="formDisabled"
													required
													toplabel
													maxlength="1000"
												></pui-text-field>
											</v-col>
										</v-row>
										<v-row dense style="margin-top: -15px">
											<!-- GROSSQUANTITY -->
											<v-col class="col-12 col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
												<pui-number-field
													:id="`grossquantity-imofalform2`"
													v-model="model.grossquantity"
													:label="$t('imofalform2.grossquantity')"
													:disabled="formDisabled"
													required
													toplabel
												></pui-number-field>
											</v-col>
											<!-- MEASUREMENTUNITCODE -->
											<v-col class="col-12 col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
												<pui-select
													:id="`measurementunitcode-imofalform2`"
													:attach="`measurementunitcode-imofalform2`"
													:label="$t('imofalform2.measurementunitcode')"
													:disabled="formDisabled"
													toplabel
													clearable
													required
													modelName="mmeasurementunits"
													v-model="model"
													reactive
													:itemsToSelect="measurementUnitCodeItemsToSelect"
													:modelFormMapping="{ measurementunitcode: 'measurementunitcode' }"
													:itemValue="['measurementunitcode']"
													:itemText="(item) => ` ${item.measurementunitsymbol} - ${item.typedescription}`"
												></pui-select>
											</v-col>
											<!-- MEASUREMENT -->
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-text-field
													:id="`measurement-imofalform2`"
													v-model="model.measurement"
													:label="$t('imofalform2.measurement')"
													:disabled="formDisabled"
													toplabel
													maxlength="70"
												></pui-text-field>
											</v-col>
										</v-row>
									</pui-field-set>
								</v-col>
							</v-row>
						</v-col>
					</v-row>

					<!-- Page Footer -->
					<pui-form-footer>
						<pui-form-footer-btns
							:formDisabled="!havePermissions(['WRITE_IMOFALFORM2'])"
							:saveDisabled="saving"
							:saveAndNew="saveAndNew"
							:saveAndUpdate="saveAndUpdate"
							:save="save"
							:back="back"
						></pui-form-footer-btns>
					</pui-form-footer>
				</v-tab-item>
			</v-tabs-items>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import hscodeUtils from './hscodeUtils';
import imofalform2Actions from '../imofalform2menu/ImoFalForm2Actions';
import docNewVersionModal from '@/components/nsw-components/DocumentNewVersionModal';

export default {
	name: 'imofalform2-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		DocNewVersionModal: docNewVersionModal
	},
	data() {
		return {
			modelName: 'imofalform2',
			actions: imofalform2Actions.actions,
			pkAttribute: 'imofalformid',
			parentModelName: 'imofalform2menu',
			modeldetail: true,
			tabmodel: 'imofalform2',
			birthdate: null,
			hscode: [],
			descripcion: {},
			hscodekey: 0,
			hscodeSelected: '',
			prueba: '',
			labelportarrival: this.$t('imofalform2.portofarrival')
		};
	},
	methods: {
		havePermissions(permissions) {
			const allowedForms = this.$store.getters.getSession.functionalities;
			// Verificar si el usuario tiene permisos para todos los permisos especificados
			return permissions.every((permission) => allowedForms.includes(permission));
		},
		opcionCambiada() {
			document.querySelector('#hscode>div').classList.add('v-text-field--edited');
		},
		buscar() {
			this.descripcion = this.hscode.find((item) => item.hscode === this.model.hscode);

			return this.descripcion;
		},

		mainSelectorEvent(event) {
			if (event.key == 'Backspace' && this.model.hscode != null) {
				this.model.hscode = null;
			}
		},

		async afterGetData() {
			this.hscode = await hscodeUtils.getHscode(this);
			// Do something after get data from server

			this.hscodekey++;
			const parentModel = this.$puiUtils.getPathModelMethodPk(this.$router, this.parentModelName);

			if (parentModel) {
				this.model.imofalformid = parentModel.pk.imofalformid;
			}
			if (this.$route.params.parentpk) {
				this.model.imofalformid = JSON.parse(atob(this.$route.params.pkgeneral)).imofalformid;
			}
		},
		fillPortCallFields(lupaModel) {
			this.model.gooddescription = lupaModel.hscodedescription;

			//this.disableEnablePortCallsFields(lupaModel);
		},
		clearPortCallFields() {
			this.model.gooddescription = null;
		}
	},
	computed: {
		packagetypecodeItemsToSelect() {
			return [{ packagetypecode: this.model.packagetypecode }];
		},
		hscodeItemsToSelect() {
			return [{ hscode: this.model.hscode }];
		},

		//gooddescriptionItemsToSelect() {
		//	return [{ hscode: this.model.hscode }];
		//},

		itemsToSelectShipid() {
			return [{ hscode: this.model.hscode, hscodedescription: this.model.gooddescription }];
		},
		measurementUnitCodeItemsToSelect() {
			return [{ measurementunitcode: this.model.measurementunitcode }];
		},
		gooddescriptionItemsToSelect() {
			return [{ hscodedescription: this.model.gooddescription }];
		}
	}
};
</script>
