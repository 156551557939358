import printReportAction from '@/components/nsw-components/PrintReportsAction';
import DocumentNewVersionAction from '@/components/nsw-components/DocumentNewVersionAction';

export default {
	actions: [
		printReportAction.printReport('CARGO_DECLARATION', 'imofalformid', 'reports.label.falForms.cargoDeclaration'),
		DocumentNewVersionAction.newVersion({
			imofalformtypeid: 2
		})
	]
};
